<template>
  <div class="client-switch-container">
    <div class="heading">
      <div class="prompt">{{ prompt }}</div>
    </div>
    <div class="body">
      <form onsubmit="return false">
        <div>
          <div class="row">
            <div class="list">
              <label for="dropdown1">Client:</label>
              <select id="dropdown1" v-model="selectedClient" @change="handleChange()">
                <option v-for="option in clientOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="list">
              <label for="dropdown2">Proofing Strategy:</label>
              <select id="dropdown2" v-model="selectedStrategy" @change="handleChange()">
                <option v-for="option in strategyOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row buttons">
          <button class="submit-button" :disabled="!isEmptyForm" @click="submitSwitchClientForm()">Switch</button>
        </div>
      </form>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import config from '../../kount.config';

export default defineComponent({
  data() {
    return {
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      prompt: `Define a custom client`,
      isEmptyForm: false,
      selectedClient: ``,
      selectedStrategy: ``,
      clientOptions: [
        { value: `SwiftAI`, label: 'SwiftAI' },
        { value: `GoBiz`, label: 'GoBiz' },
      ],
      strategyOptions: [
        { value: 'NAO', label: 'NAO' },
        { value: 'NAO+Addr', label: 'NAO+Addr' },
      ],
    };
  },
  methods: {
    handleChange() {
      if (this.selectedStrategy !== `` && this.selectedClient !== ``) {
        this.isEmptyForm = true;
      } else {
        this.isEmptyForm = false;
      }
    },
    submitSwitchClientForm() {
      config.kountClientId = this.selectedClient === window.__env.clientSpringCustomer ? this.clientSpringClientId : this.clientGoClientId;

      const customClientPath = `${config.hostname}${config.routes?.identify}?kcid=${config.kountClientId}&proofing_strategy=${this.selectedStrategy}`;
      window.location.href = customClientPath;
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.client-switch-container {
  font-family: Sansita;
  margin: 0 auto;
  color: #ffffff;
  background-color: #2a2f32;
  height: 100vh;
  width: 100vw;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35vh;
    width: 100vw;

    .prompt {
      font-size: 35px;
      font-family: Sansita One;
      font-weight: 400;
    }
  }

  .body {
    height: 50vh;
    width: 100vw;

    .submit-button {
      border-radius: 8px;
      border: 4px solid var(--accent-crayola-caribbean-green, #37d4ad);
      background: var(--accent-crayola-caribbean-green, #37d4ad);
      width: 201px;
      height: 52px;

      color: var(--dark-gray, #2a2f32);
      font-family: Sansita;
      font-size: 22px;
    }

    .submit-button:disabled {
      opacity: 0.3;
      color: white;
    }
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;

    .list {
      display: flex;
      height: 5vh;
      margin-bottom: 5vh;
      font-size: 20px;

      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35vw;
        padding: 5px;
      }

      > select {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Sansita;
        width: 25vw;
      }
    }
  }
}

/* Laptop/Desktop Styles */
@media (min-width: 767px) {
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;

    .list {
      display: flex;
      height: 5vh;
      margin-bottom: 5vh;
      font-size: 20px;

      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20vw;
        padding: 5px;
      }

      > select {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: Sansita;
        width: 25vw;
      }
    }
  }
}
</style>
