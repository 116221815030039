<template>
  <div class="identify-phone-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div @click="switchClient()" class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
        <img v-else class="GoBiz-logo" src="../assets/images/GoBiz-logo.png" />
      </div>
      <div class="row brand-name">{{ brandName }}</div>
    </div>
    <div v-if="isIdentified" class="body">
      <div class="row loader">
        <img :src="spinnerSrc" alt="spinnerImage" />
      </div>
    </div>
    <div v-else class="body">
      <div class="phone-number-input">
        <div class="row prompt">Access or create your account</div>
        <div class="row">
          <div class="input-wrapper">
            <div class="phone-number-hints">
              <img src="https://hatscripts.github.io/circle-flags/flags/us.svg" width="24" />
              <div class="country-code">
                {{ countryCode }}
              </div>
            </div>
            <input
              type="tel"
              pattern="\d*"
              class="input-field"
              v-model="phoneNumber"
              @input="validatePhoneNumber()"
              v-puppeteer="'identifierInput'"
              :maxlength="phoneNumberInputLength"
            />
          </div>
        </div>
        <div class="row input-errors" v-if="error">
          {{ error }}
        </div>
      </div>
      <div class="buttons">
        <button class="main-button" :disabled="!isValidPhoneNumber" @click="onSubmit()" v-puppeteer="'submitButton'">Sign In/Up</button>
      </div>
      <div class="buttons">
        <button
          class="sub-button"
          :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }"
          @click="resetIdentity()"
          v-puppeteer="'submitButton'"
        >
          Reset
        </button>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';
import config from '../../kount.config';
import { AsYouType } from 'libphonenumber-js';

export default defineComponent({
  data() {
    return {
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      brandName: 'SwiftAI!',
      countryCode: '+1',
      phoneNumber: '',
      isIdentified: false,
      isValidPhoneNumber: false,
      error: '',
      phoneNumberInputLength: 13,
      spinnerSrc: require('/src/assets/images/SwiftAI-spinner.png'),
    };
  },
  created() {
    if (config.kountClientId === this.clientGoClientId) {
      this.brandName = `SwiftAI!`;
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.brandName = `GoBiz!`;
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
      this.spinnerSrc = require('/src/assets/images/GoBiz-spinner.png');
    }
  },
  methods: {
    switchClient() {
      const clientSwitchPath = `${config.hostname}/client-switch`;
      window.location.href = clientSwitchPath;
    },
    validatePhoneNumber() {
      this.isValidPhoneNumber = false;
      if (this.phoneNumber[0] === '1') {
        this.error = 'First digit cannot be 1.';
        this.phoneNumber = '1';
      } else {
        if (this.phoneNumber[0] === '(' && this.phoneNumber[4] === undefined) {
          this.phoneNumber = this.phoneNumber;
        } else {
          const phoneNumberDigitOnly = this.phoneNumber.replace(/\D/g, '');
          this.phoneNumber = new AsYouType('US').input(phoneNumberDigitOnly).replace(/\s/g, '');
        }
        if (this.phoneNumber.length !== this.phoneNumberInputLength) {
          this.error = `Please enter a valid 10-digit phone number.`;
        } else {
          this.error = '';
          this.isValidPhoneNumber = true;
        }
      }
    },
    onSubmit() {
      this.isIdentified = true;
      if (this.isValidPhoneNumber) {
        this.isValidPhoneNumber = false;
        const phoneNumberDigitOnly = this.phoneNumber.replace(/\D/g, '');

        kount.phone
          .identify(phoneNumberDigitOnly)
          .then((result) => {
            console.warn(result);
          })
          .catch((err) => {
            console.warn(err);
            handleRedirect('/error');
          });
      }
    },
    resetIdentity() {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('proofingStrategy') === null || ``) {
        this.proofingStrategy = `NAO`;
      } else {
        this.proofingStrategy = urlParams.get('proofingStrategy') as string;
      }

      handleRedirect(`/reset?kcid=${config.kountClientId}&proofing_strategy=${this.proofingStrategy}`);
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.SwiftAI-theme {
  background-color: #2a2f32;
  color: #ffffff;

  .main-button {
    border-radius: 8px;
    border: 4px solid var(--accent-crayola-caribbean-green, #37d4ad);
    background: var(--accent-crayola-caribbean-green, #37d4ad);
    width: 201px;
    height: 52px;

    color: var(--dark-gray, #2a2f32);
    font-family: Sansita;
    font-size: 22px;
  }

  .sub-button {
    border: 4px solid var(--dark-gray, #2a2f32);
    background: var(--dark-gray, #2a2f32);

    color: var(--background-caribbean-green, #dafcf3);
    font-family: Sansita;
    font-size: 22px;
  }
}

.GoBiz-theme {
  background-color: rgba(233, 219, 254, 255);
  color: #7d56c2;

  .main-button {
    border-radius: 8px;
    border: 4px solid var(--accent-crayola-caribbean-green, #fa8ed5);
    background: var(--accent-crayola-caribbean-green, #fa8ed5);
    width: 201px;
    height: 52px;

    color: var(--dark-gray, #2a2f32);
    font-family: Sansita;
    font-size: 22px;
  }

  .sub-button {
    border: 4px solid var(--accent-crayola-caribbean-green, rgba(233, 219, 254, 255));
    background: var(--accent-crayola-caribbean-green, rgba(233, 219, 254, 255));

    color: var(--dark-gray, #2a2f32);
    font-family: Sansita;
    font-size: 22px;
  }
}

.identify-phone-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 40vh;
    width: 100vw;

    .brand-logo {
      height: 35vh;
      align-items: center;
    }

    .GoBiz-logo {
      border-radius: 50%;
      width: 100px;
    }

    .brand-name {
      height: 15vh;
      margin: -80px 0px 0px 0px;
      font-size: 48px;
      font-family: Sansita One;
      font-weight: 400;
    }
  }

  .body {
    height: 50vh;

    .phone-number-input {
      height: 15vh;
      width: 100vw;

      .prompt {
        height: 5vh;
        font-size: 22px;
        font-family: Sansita;
      }

      .phone-number-hints {
        margin: 5px 0px 5px 15px;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        .country-code {
          padding: 5px;
          font-family: Patrick Hand;
          font-size: 18px;
        }
      }

      .input-field {
        margin: 5px 15px 5px 5px;
        border: none;

        font-family: Patrick Hand;
        font-size: 18px;
      }

      .input-errors {
        margin: -20px 0px 0px 0px;
        height: 2vh;
        font-size: 12px;
        padding: 10px 40px 0px 40px;
      }
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .main-button:disabled {
        opacity: 0.3;
        color: white;
      }
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
    .input-wrapper {
      background-color: white;
      width: 275px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 200px;
        letter-spacing: 0.4rem;
      }
    }
  }

  /* Laptop/Desktop Styles */
  @media (min-width: 767px) {
    .input-wrapper {
      background-color: white;
      width: 285px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 250px;
        letter-spacing: 0.45rem;
      }
    }
  }
}
</style>
