<template>
  <div class="verify-contact-info-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
      </div>
    </div>
    <div class="body">
      <div v-if="!contactInfosCaptured">
        <div class="row prompt">Tell us more about you</div>
        <div class="input-containers">
          <div class="row" v-for="(value, key) in addressInputs">
            <div class="row-input">
              <div class="input-identifier">{{ key }}</div>
              <div class="input-wrapper">
                <input class="input-field" v-model="addressInputs[key]" :placeholder="value" />
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="main-button" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }" @click="onSubmit()">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';
import config from '../../kount.config';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      buttonText: 'Submit',
      contactInfosCaptured: true,
      contactInfos: {
        firstNameLegal: ``,
        lastNameLegal: ``,
        phoneNumber: ``,
        dateOfBirth: ``,
        email: ``,
        address: {
          type: 0,
          line1: ``,
          line2: ``,
          city: ``,
          region: ``,
          countryCode: ``,
          postalCode: ``,
          isPrimary: true,
        },
      },
      addressInputs: {
        line1: `2 Fern St`,
        line2: `Apt 113`,
        city: `Las Vegas`,
        state: `Nevada`,
        zipCode: `89123`,
      },
      brandName: ``,
    };
  },
  created() {
    if (config.kountClientId === this.clientGoClientId) {
      this.brandName = `SwiftAI!`;
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.brandName = `GoBiz!`;
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
    }

    const remoteSession = new URLSearchParams(window.location.search).get('remote_session');
    if (remoteSession === `enroll`) {
      handleRedirect(`/identity-verified`);
    } else {
      const dummyIdentity = new kount.identity.Identity({});
      kount.identity
        .resolveIdentity(dummyIdentity)
        .then((result) => {
          if (result === `/identity-verified`) {
            handleRedirect(result);
          } else {
            if (typeof result === `object` && result.address === true) {
              this.mapContactInfos(result as Record<string, boolean>);
            } else {
              handleRedirect(`/identity-verified`);
            }
          }
        })
        .catch((err) => {
          console.warn(err);
          handleRedirect('/error');
        });
    }
  },
  methods: {
    mapContactInfos(result: Record<string, boolean>): void {
      const contactInfos: Record<string, string | boolean | object> = this.contactInfos;

      for (const property in result) {
        if (result[property] === true) {
          contactInfos[property] = result[property];
        } else {
          delete contactInfos[property];
        }
      }

      if (Object.keys(this.contactInfos).length < 0) {
        this.contactInfosCaptured = true;
      } else {
        this.contactInfosCaptured = false;
      }
    },
    mapIdentity(): kount.identity.Identity {
      this.contactInfos.address = {
        type: 0,
        line1: this.addressInputs.line1,
        line2: this.addressInputs.line2,
        city: this.addressInputs.city,
        region: this.addressInputs.state,
        countryCode: `1`,
        postalCode: this.addressInputs.zipCode,
        isPrimary: true,
      };

      return new kount.identity.Identity({
        addressList: [this.contactInfos.address],
      });
    },
    onSubmit(): void {
      const contactInfoIdentity = this.mapIdentity();
      kount.identity
        .resolveIdentity(contactInfoIdentity)
        .then(() => {
          handleRedirect(`/identity-verified`);
        })
        .catch((err) => {
          console.warn(err);
          handleRedirect('/error');
        });
    },
  },
});
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.verify-contact-info-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 20vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }
  }

  .body {
    height: 70vh;

    .prompt {
      height: 5vh;
      font-size: 22px;
      font-family: Sansita;
    }

    .input-identifier {
      color: #b4b4b4;
      font-family: Sansita;
      margin: 10px 0px 10px 0px;
      line-height: 20px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }

    .phone-number-hints {
      margin: 5px 0px 5px 15px;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      .country-code {
        padding: 5px;
        font-family: Patrick Hand;
        font-size: 18px;
      }
    }

    .input-field {
      margin: 5px 15px 5px 5px;
      border: none;

      font-family: Patrick Hand;
      font-size: 18px;
    }

    .input-errors {
      height: 2vh;
      font-size: 12px;
      padding: 10px 40px 0px 40px;
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
    .input-containers {
      height: 53vh;
    }

    .row-input {
      margin: 10px 30px 0px 30px;
    }

    .input-wrapper {
      background-color: white;
      width: 100%;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 100%;
        font-family: Sansita;
        padding: 5px 5px 5px 5px;
        line-height: 24px;
        letter-spacing: 0.18px;
      }
    }
  }

  /* Laptop/Desktop Styles */

  @media (min-width: 767px) {
    .input-containers {
      height: 53vh;
    }

    .row-input {
      margin: 10px 30px 0px 30px;
    }

    .input-wrapper {
      background-color: white;
      align-items: center;
      justify-content: center;
      width: 300px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 100%;
        font-family: Sansita;
        padding: 5px 5px 5px 5px;
        line-height: 24px;
        letter-spacing: 0.18px;
      }
    }
  }
}
</style>
