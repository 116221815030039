import { createWebHistory, createRouter } from 'vue-router';
import ClientSwitch from './pages/client-switch.vue';
import ConsentConfirmation from './pages/consent-confirmation.vue';
import ErrorHandlingPage from './pages/error-page.vue';
import IdentifyPhone from './pages/identify-phone.vue';
import IdentityVerified from './pages/identity-verified.vue';
import kount from '@kount/browser-sdk';
import ResetPublicIdentifer from './pages/reset-public-identifier.vue';
import VerificationSuccess from './pages/verification-success.vue';
import VerifyContactInfo from './pages/verify-contact-info.vue';
import VerifyPersonalInfo from './pages/verify-personal-info.vue';
import VerifyStepUp from './pages/verify-identity-step-up.vue';

var prefix = extractPathPrefix();

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: IdentifyPhone,
    id: 'home',
  },
  {
    path: prefix + kount.defaultRoutes.identify,
    name: 'Identify Phone',
    component: IdentifyPhone,
    id: 'identify-phone',
  },
  {
    path: prefix + '/client-switch',
    name: 'Client Switch',
    component: ClientSwitch,
  },
  {
    path: prefix + kount.defaultRoutes.consentConfirmation,
    name: 'Confirm consent',
    component: ConsentConfirmation,
  },
  {
    path: prefix + kount.defaultRoutes.verifyPersonalInfo,
    name: 'Verify Personal Information',
    component: VerifyPersonalInfo,
    id: 'verify-personal-info',
  },
  {
    path: prefix + kount.defaultRoutes.verifyContactInfo,
    name: 'Verify Contact Information',
    component: VerifyContactInfo,
    id: 'verify-contact-info',
  },
  {
    path: prefix + kount.defaultRoutes.verifyIdentityStepUp,
    name: 'Verify Identity Step-Up',
    component: VerifyStepUp,
    id: 'verify-identity-step-up',
  },
  {
    path: prefix + kount.defaultRoutes.verificationSuccess,
    name: 'Verification Success',
    component: VerificationSuccess,
    id: 'verification-success',
  },
  {
    path: prefix + kount.defaultRoutes.identityVerified,
    name: 'Identity Verified',
    component: IdentityVerified,
    id: 'verified-identity',
  },
  {
    path: prefix + '/reset',
    name: 'Reset Public Identifier',
    component: ResetPublicIdentifer,
  },
  {
    path: prefix + kount.defaultRoutes.error,
    name: 'Error',
    component: ErrorHandlingPage,
    id: 'error-handling',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export function handleRedirect(redirectPath: kount.RedirectURI): void {
  console.debug(`re-routing to ${redirectPath}`);
  let path = prefix + redirectPath;
  router.push(path);
}

export function extractPathPrefix(): string {
  let addr = new URL(window.__env.hostname);
  if (addr.pathname == '/') {
    return '';
  } else {
    return addr.pathname;
  }
}

export function redirectWithNewSession(redirectPath: kount.RedirectURI): void {
  console.debug(`re-routing to ${redirectPath}`);
  let path = prefix + redirectPath;
  window.location.href = path;
}

export default router;
