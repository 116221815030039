<template>
  <div class="success-container">
    <div class="heading">
      <div class="row brand-logo">
        <img src="../assets/images/SwiftAI-logo-small.png" />
      </div>
    </div>
    <div class="body">
      <div class="prompt">
        <div class="row prompt-text">Welcome to</div>
        <div class="row prompt-text">your account</div>
      </div>

      <div class="buttons">
        <button class="sign-out-button" @click="backToHomePage()" v-puppeteer="'submitButton'">Sign out</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { handleRedirect } from '../router';
export default defineComponent({
  data() {
    return {
      error: '',
    };
  },
  methods: {
    backToHomePage() {
      handleRedirect('/identify');
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.success-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 30vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }
  }

  .body {
    height: 60vh;

    .prompt {
      height: 25vh;

      .prompt-text {
        font-family: Sansita;
        font-size: 34px;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .sign-out-button {
        border-radius: 8px;
        border: 4px solid var(--accent-crayola-caribbean-green, #37d4ad);
        background: var(--accent-crayola-caribbean-green, #37d4ad);
        width: 201px;
        height: 52px;

        color: var(--dark-gray, #2a2f32);
        font-family: Sansita;
        font-size: 22px;
      }
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
  }

  /* Laptop/Desktop Styles */
  @media (min-width: 767px) {
  }
}
</style>
