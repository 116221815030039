<template>
  <div class="error-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
      </div>
    </div>
    <div class="body">
      <div class="prompt-container">
        <div class="row prompt prompt-text-header">System error:</div>
        <div class="row prompt prompt-text-body">Please contact administrator.</div>
      </div>

      <div class="buttons">
        <button class="main-button" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }" @click="backToHomePage()">
          Start Over
        </button>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { redirectWithNewSession } from '../router';
import config from '../../kount.config';

export default defineComponent({
  data() {
    return {
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      error: '',
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
    };
  },
  created() {
    if (config.kountClientId === this.clientGoClientId) {
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
    }
  },
  methods: {
    backToHomePage() {
      redirectWithNewSession(`/identify?kcid=${config.kountClientId}&proofing_strategy=${config.proofingStrategyAlias}`);
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');

.error-container {
  margin: 0 auto;
  color: #ffffff;
  background-color: #2a2f32;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 30vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }
  }

  .body {
    height: 60vh;

    .prompt-container {
      height: 25vh;

      .prompt {
        font-family: Sansita;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;
      }

      .prompt-text-header {
        font-size: 34px;
      }

      .prompt-text-body {
        font-size: 24px;
      }
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
  }

  /* Laptop/Desktop Styles */
  @media (min-width: 767px) {
  }
}
</style>
