import kount from '@kount/browser-sdk';

// Configure the Equifax SDK
export default {
  hostname: (<any>window).__env.hostname,
  routes: kount.defaultRoutes,
  createInquirySessionURL: (<any>window).__env.demoGoService + `/create-inquiry-session`,
  passSubscriberNonceURL: (<any>window).__env.demoSpringService + `/subscriber-nonce`,
  proofingStrategyAlias: 'NAO',
  kountUserType: 'DISVueClient',
  kountClientGoId: '611000',
  kountClientSpringId: '999666',

  kountDomain: (<any>window).__env.domain,
  grpcEnabled: true,
  grpcAddress: (<any>window).__env.grpcAddress,
  dpopEnabled: true,
  kountEnabled: true,
  kountCollectorUrl: 'tst.kaptcha.com',
} as Partial<kount.Configuration>;
