<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts">
import Navigation from '../components/navigation.vue';

export default {
  components: {
    navigation: Navigation,
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: 'Open Sans';
}

nav {
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  padding-right: 116px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  margin-bottom: 64px;
}

#home-page,
#identify-phone-page,
#verification-page,
#registration-page,
#webauthn-registration-page,
#checkemail-page,
#wa-authentication-page,
#authentication-page,
#enrollment-page,
#error-page,
#not-found-page,
#collect-personal-info-page,
#collect-contact-info-page,
#defer-verification-page,
#proofing-complete-page,
#step-up-page {
  padding-left: 1rem;
}

#registration-page label {
  display: block;
  position: relative;
}

#registration-page input {
  margin-bottom: 0.5rem;
}

#registration-page button {
  display: block;
}

#memory-store {
  margin: 20px;
}

dd {
  font-family: 'Courier New', Courier, monospace;
}

.error {
  border: 1px solid;
  padding: 15px 10px 15px 10px;
  color: #d8000c;
  width: 500px;
  background-color: #ffbaba;
}
</style>
Navigation
