import { createApp } from 'vue';
import router from './router';
// @ts-ignore
import App from './App.vue';

// layouts
import DefaultLayout from './layouts/default.vue';

// components
import EquifaxLogo from './components/logo.vue';

// directives
import PuppeteerDirective from './directives/puppeteer';

// global components
import footerTrustMark from './components/footer-trust-mark.vue';

// setup code
const app = createApp(App);
app.use(router);
app.mount('#app');

// layouts
app.component('default-layout', DefaultLayout);

// components
app.component('efx-logo', EquifaxLogo);
app.component('footer-trust-mark', footerTrustMark);

// directives
// TODO: if DEV
app.directive('puppeteer', PuppeteerDirective);
