import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/images/SwiftAI-logo.png'
import _imports_1 from '../assets/images/GoBiz-logo.png'


const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "row brand-logo" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  class: "GoBiz-logo",
  src: _imports_1
}
const _hoisted_5 = { class: "body" }
const _hoisted_6 = { class: "row loader" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["consent-container", { 'SwiftAI-theme': _ctx.isSwiftAITheme, 'GoBiz-theme': _ctx.isGoBizTheme }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isGoBizTheme)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          src: _ctx.spinnerSrc,
          alt: "spinnerImage"
        }, null, 8, _hoisted_7)
      ])
    ])
  ], 2))
}