<template>
  <div class="consent-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
        <img v-else class="GoBiz-logo" src="../assets/images/GoBiz-logo.png" />
      </div>
    </div>
    <div class="body">
      <div class="row loader">
        <img :src="spinnerSrc" alt="spinnerImage" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';
import { defineComponent } from 'vue';
import config from '../../kount.config';

export default defineComponent({
  data() {
    return {
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      isGoBizTheme: false,
      isSwiftAITheme: true,
      spinnerSrc: require('/src/assets/images/SwiftAI-spinner.png'),
    };
  },
  mounted() {
    if (config.kountClientId === this.clientGoClientId) {
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
      this.spinnerSrc = require('/src/assets/images/GoBiz-spinner.png');
    }

    window.setTimeout(() => {
      kount.consent
        .checkDecision()
        .then((url) => {
          if (url) {
            handleRedirect(url);
          }
        })
        .catch((err) => {
          console.warn(err);
          handleRedirect('/error');
        });
    }, 1000);
  },
});
</script>

<style>
.SwiftAI-theme {
  background-color: #2a2f32;
  color: #ffffff;
}

.GoBiz-theme {
  background-color: rgba(233, 219, 254, 255);
  color: #7d56c2;
}

.consent-container {
  margin: 0 auto;
  color: #ffffff;
  background-color: #2a2f32;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 40vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }

    .GoBiz-logo {
      border-radius: 50%;
      width: 100px;
    }
  }

  .body {
    height: 60vh;
  }
}
</style>
