<template>
  <nav>
    <div v-if="serverPort == 8088">
      <h1 style="color: blue">b2b.com</h1>
    </div>
    <div v-else>
      <efx-logo />
    </div>
    <slot />
    <div id="nav-menu" v-for="(r, i) in displayRoutes" v-bind:key="i">
      <router-link :to="r.path" :id="r.id">{{ r.name }}</router-link>
    </div>
  </nav>
</template>

<script lang="ts">
export type NavRoute = {
  id: string;
  name: string;
  path: string;
};

import { routes } from '../router';

export default {
  data() {
    const displayRoutes = routes.filter((route) => {
      switch (route.name.toLowerCase()) {
        case 'error':
        case 'not found':
        case 'login success':
        case 'defer verification':
        // Disabling display as we are focusing on mobile path only atm
        // TODO:: Enable this once we address email verification?
        case 'check email':
          return false;
        default:
          return true;
      }
    });
    return { displayRoutes };
  },
  computed: {
    serverPort() {
      return window.location.port;
    },
  },
  components: {},
};
</script>

<style>
#nav-menu {
  padding-left: 1rem;
}
</style>
