<template>
  <div class="verify-personal-info-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
      </div>
    </div>
    <div class="body">
      <div class="row prompt">Tell us about you</div>
      <div class="input-containers">
        <div class="row">
          <div class="row-input">
            <div class="input-identifier">First Name</div>
            <div class="input-wrapper">
              <input class="input-field" v-model="legalFirstName" v-puppeteer="'identifierInput'" placeholder="Jane" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row-input">
            <div class="input-identifier">Last Name</div>
            <div class="input-wrapper">
              <input class="input-field" v-model="legalLastName" v-puppeteer="'identifierInput'" placeholder="Doe" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row-input">
            <div class="input-identifier">Email Address</div>
            <div class="input-wrapper">
              <input class="input-field" v-model="email" v-puppeteer="'identifierInput'" placeholder="example@domain.com" />
            </div>
            <div class="row input-errors" v-if="error">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="main-button" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }" @click="onSubmit()">
          {{ buttonText }}
        </button>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';
import config from '../../kount.config';
import { LocationQueryValue } from 'vue-router';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
      buttonText: 'Submit',
      buttonTextLogout: 'Logout',
      legalFirstName: 'Jane',
      legalLastName: 'Doe',
      dateOfBirth: '',
      suffix: '5',
      month: '11',
      day: '10',
      year: '1988',
      email: 'jane.doe@gmail.com',
      error: '',
      brandName: '',
      publicId: [] as LocationQueryValue[],
    };
  },
  created() {
    this.publicId = this.$route.query.public_id as LocationQueryValue[];

    if (config.kountClientId === this.clientGoClientId) {
      this.brandName = `SwiftAI!`;
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.brandName = `GoBiz!`;
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
    }

    // const dummyIdentity = new kount.identity.Identity({});
    // kount.identity
    //   .resolveIdentity(dummyIdentity)
    //   .then((result) => {
    //     console.warn(`debug`);

    //     console.warn(result);
    //     if (typeof result === `object` && result.address === true) {
    //       handleRedirect(config.routes?.verifyContactInfo as string);
    //     }
    //   })
    //   .catch((err) => {
    //     console.warn(err);
    //     handleRedirect('/error');
    //   });
  },
  methods: {
    validateInput(attribute: string, event: MouseEvent) {
      const validationMap: Record<string, (attr: string) => boolean> = {
        firstName: kount.identity.validateFirstNameLegal,
        middleName: kount.identity.validateMiddleNameLegal,
        lastName: kount.identity.validateLastNameLegal,
        suffix: kount.identity.validateSuffix,
        nationalIdentifier: kount.identity.validateNationalIdentifier,
      } as const;

      const button: HTMLButtonElement = event.target as HTMLButtonElement;

      if (button && button.value)
        validationMap[attribute] && validationMap[attribute](button.value)
          ? button.classList.remove('invalid')
          : button.classList.add('invalid');
    },
    onSubmit() {
      const partialIdentity = new kount.identity.Identity({
        firstNameLegal: this.legalFirstName,
        lastNameLegal: this.legalLastName,
        email: {
          email: this.email,
          type: 0,
        },
      });

      this.buttonText = 'Verifying...';
      kount.identity
        .resolveIdentity(partialIdentity)
        .then((result) => {
          if (result !== `/identity-verified`) {
            handleRedirect(`/verify-contact-info`);
          } else {
            handleRedirect(result);
          }
        })
        .catch((err) => {
          console.warn(err);
          handleRedirect('/error');
        });
    },
  },
});
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.verify-personal-info-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 20vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }
  }

  .body {
    height: 70vh;

    .prompt {
      height: 5vh;
      font-size: 22px;
      font-family: Sansita;
    }

    .input-identifier {
      color: #b4b4b4;
      font-family: Sansita;
      margin: 10px 0px 10px 0px;
      line-height: 20px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
    }

    .phone-number-hints {
      margin: 5px 0px 5px 15px;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      .country-code {
        padding: 5px;
        font-family: Patrick Hand;
        font-size: 18px;
      }
    }

    .input-field {
      margin: 5px 15px 5px 5px;
      border: none;

      font-family: Patrick Hand;
      font-size: 18px;
    }

    .input-errors {
      height: 2vh;
      font-size: 12px;
      padding: 10px 40px 0px 40px;
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
    .input-containers {
      height: 40vh;
    }

    .row-input {
      margin: 10px 30px 0px 30px;
    }

    .input-wrapper {
      background-color: white;
      width: 100%;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 100%;
        font-family: Sansita;
        padding: 5px 5px 5px 5px;
        line-height: 24px;
        letter-spacing: 0.18px;
      }
    }
  }

  /* Laptop/Desktop Styles */

  @media (min-width: 767px) {
    .input-containers {
      height: 30vh;
    }

    .row-input {
      margin: 10px 30px 0px 30px;
    }

    .input-wrapper {
      background-color: white;
      align-items: center;
      justify-content: center;
      width: 300px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 100%;
        font-family: Sansita;
        padding: 5px 5px 5px 5px;
        line-height: 24px;
        letter-spacing: 0.18px;
      }
    }
  }
}
</style>
