<template>
  <div class="success-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
        <img v-else class="GoBiz-logo" src="../assets/images/GoBiz-logo.png" />
      </div>
    </div>
    <div class="body">
      <div class="prompt">
        <div class="row prompt-text">Welcome to</div>
        <div class="row prompt-text">your account</div>
      </div>

      <div class="buttons">
        <button
          class="main-button"
          :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }"
          @click="backToHomePage()"
          v-puppeteer="'submitButton'"
        >
          Sign out
        </button>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { handleRedirect } from '../router';
import config from '../../kount.config';

export default defineComponent({
  data() {
    return {
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      brandName: 'SwiftAI!',
      error: '',
    };
  },
  created() {
    if (config.kountClientId === this.clientGoClientId) {
      this.brandName = `SwiftAI!`;
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.brandName = `GoBiz!`;
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
    }
  },
  methods: {
    backToHomePage() {
      handleRedirect('/identify');
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.success-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .heading {
    height: 40vh;
    width: 100vw;

    .brand-logo {
      height: 35vh;
      align-items: center;
    }

    .GoBiz-logo {
      border-radius: 50%;
      width: 100px;
    }
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 30vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }
  }

  .body {
    height: 60vh;

    .prompt {
      height: 25vh;

      .prompt-text {
        font-family: Sansita;
        font-size: 34px;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
  }

  /* Laptop/Desktop Styles */
  @media (min-width: 767px) {
  }
}
</style>
