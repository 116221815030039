<template>
  <div id="step-up-page" class="flex-column">
    <h3 class="title-text">
      {{ message }}
    </h3>
  </div>
</template>

<script lang="ts">
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      message: '',
    };
  },
  created() {
    this.message = 'Please check your phone for document verification.';
    /* NOTE:
    Landing on this page indicates that the client has attempted to collect
    all identity attributes and is ready to complete proofing.
    */
    kount.identity.verifyStepUp().then(handleRedirect);
  },
});
</script>
