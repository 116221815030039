<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script lang="ts">
import kount from '@kount/browser-sdk';
import { defineComponent } from 'vue';
import config from '../kount.config';
import { handleRedirect } from './router';

export const defaultLayout = 'default';

export default defineComponent({
  data() {
    return {
      serviceApiKey: window.__env.demoServiceGoApiKey,
    };
  },
  mounted() {
    this.initConfig();
    this.startApp();
  },
  methods: {
    initConfig() {
      config.kountClientId = window.__env.clientGoClientId;
      const urlParams = new URLSearchParams(window.location.search);
      const kountClientIdParam = String(urlParams.get('kcid'));

      if (kountClientIdParam === config.kountClientSpringId) {
        config.kountClientId = kountClientIdParam;
        this.serviceApiKey = window.__env.demoSpringServiceApiKey;
        config.createInquirySessionURL = window.__env.demoSpringService + `/create-inquiry-session`;
        config.passSubscriberNonceURL = window.__env.demoSpringService + `/subscriber-nonce`;
      } else {
        this.serviceApiKey = window.__env.demoGoServiceApiKey;
        config.createInquirySessionURL = window.__env.demoGoService + `/create-inquiry-session`;
        config.passSubscriberNonceURL = window.__env.demoGoService + `/subscriber-nonce`;
      }

      const urlParamProofingStrategy = urlParams.get('proofing_strategy');
      if (urlParamProofingStrategy !== null) {
        config.proofingStrategyAlias = urlParamProofingStrategy.replace(/\ /g, '+');
      }
    },
    async startApp() {
      await kount.customer
        .createInquirySession(this.serviceApiKey, config.createInquirySessionURL!, config.proofingStrategyAlias!)
        .catch((err) => {
          console.warn(err);
          handleRedirect('/error');
        });
      kount.init(config); // <- imported kount.config.ts
      console.log(config);
    },
  },
  computed: {
    layout(): string {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    },
  },
});
</script>
