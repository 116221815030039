<template>
  <div class="reset-container" :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }">
    <div class="heading">
      <div @click="switchClient()" class="row brand-logo">
        <img v-if="!isGoBizTheme" src="../assets/images/SwiftAI-logo.png" />
        <img v-else class="GoBiz-logo" src="../assets/images/GoBiz-logo.png" />
      </div>
    </div>
    <div class="body">
      <div class="phone-number-input">
        <div class="row prompt">Reset your account</div>
        <div class="row">
          <div class="input-wrapper">
            <div class="phone-number-hints">
              <img src="https://hatscripts.github.io/circle-flags/flags/us.svg" width="24" />
              <div class="country-code">
                {{ countryCode }}
              </div>
            </div>
            <input
              type="tel"
              pattern="\d*"
              class="input-field"
              v-model="phoneNumber"
              @input="validatePhoneNumber()"
              v-puppeteer="'identifierInput'"
              :maxlength="phoneNumberInputLength"
            />
          </div>
        </div>
        <div class="row input-errors" v-if="error">
          {{ error }}
        </div>
      </div>
      <div class="buttons">
        <button
          class="main-button"
          :class="{ 'SwiftAI-theme': isSwiftAITheme, 'GoBiz-theme': isGoBizTheme }"
          :disabled="!isValidPhoneNumber"
          @click="onSubmit()"
          v-puppeteer="'submitButton'"
        >
          Reset
        </button>
      </div>
      <div class="buttons">
        <button class="sub-button" @click="backToHomePage()" v-puppeteer="'submitButton'">Back</button>
      </div>
    </div>
    <footer-trust-mark></footer-trust-mark>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { redirectWithNewSession } from '../router';
import { AsYouType } from 'libphonenumber-js';
import config from '../../kount.config';
import kount from '@kount/browser-sdk';
import { handleRedirect } from '../router';

export default defineComponent({
  data() {
    return {
      kountClientId: ``,
      proofingStrategy: ``,
      isSwiftAITheme: true,
      isGoBizTheme: false,
      clientSpringClientId: window.__env.clientSpringClientId,
      clientGoClientId: window.__env.clientGoClientId,
      countryCode: '+1',
      phoneNumber: '',
      homePage: ``,
      isValidPhoneNumber: false,
      error: '',
      phoneNumberInputLength: 13,
    };
  },
  created() {
    if (config.kountClientId === this.clientGoClientId) {
      this.isGoBizTheme = false;
      this.isSwiftAITheme = true;
    } else {
      this.isGoBizTheme = true;
      this.isSwiftAITheme = false;
    }

    this.homePage = `/identify?kcid=${config.kountClientId}&proofing_strategy=${config.proofingStrategyAlias}`;
  },
  methods: {
    switchClient() {
      const reloadPathDemoSpring = `${window.location.origin}${window.location.pathname}?kountClientId=${this.clientSpringClientId}`;
      const reloadPathDemoGo = `${window.location.origin}${window.location.pathname}?kountClientId=${this.clientGoClientId}`;
      let reloadPathNewClient = config.kountClientId === this.clientGoClientId ? reloadPathDemoSpring : reloadPathDemoGo;
      window.location.href = reloadPathNewClient;
    },
    validatePhoneNumber() {
      this.isValidPhoneNumber = false;
      if (this.phoneNumber[0] === '1') {
        this.error = 'First digit cannot be 1.';
        this.phoneNumber = '1';
      } else {
        if (this.phoneNumber[0] === '(' && this.phoneNumber[4] === undefined) {
          this.phoneNumber = this.phoneNumber;
        } else {
          const phoneNumberDigitOnly = this.phoneNumber.replace(/\D/g, '');
          this.phoneNumber = new AsYouType('US').input(phoneNumberDigitOnly).replace(/\s/g, '');
        }
        if (this.phoneNumber.length !== this.phoneNumberInputLength) {
          this.error = `Please enter a valid 10-digit phone number.`;
        } else {
          this.error = '';
          this.isValidPhoneNumber = true;
        }
      }
    },
    async onSubmit() {
      if (this.isValidPhoneNumber) {
        const urlParams = new URLSearchParams(window.location.search);
        let resetIdentityUrl = new URL(`${window.__env.demoGoService}/reset-identity`);
        let bearerToken = window.__env.demoGoServiceApiKey;

        if (urlParams.get('kcid') !== config.kountClientId) {
          resetIdentityUrl = new URL(`${window.__env.demoSpringService}/reset-identity`);
          bearerToken = window.__env.demoSpringServiceApiKey;
        } else {
          resetIdentityUrl = new URL(`${window.__env.demoGoService}/reset-identity`);
          bearerToken = window.__env.demoGoServiceApiKey;
        }

        const phoneNumberDigitOnly = this.phoneNumber.replace(/\D/g, '');

        await kount.customer
          .resetIdentity(phoneNumberDigitOnly, resetIdentityUrl.toString(), bearerToken)
          .then(() => {
            this.backToHomePage();
          })
          .catch((error) => {
            console.warn(error);
            handleRedirect('/error');
          });
      }
    },
    backToHomePage() {
      redirectWithNewSession(`/identify?kcid=${config.kountClientId}&proofing_strategy=${config.proofingStrategyAlias}`);
    },
  },
});
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Sansita+One');
@import url('https://fonts.googleapis.com/css?family=Sansita');
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand');

.reset-container {
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    justify-content: center;
  }

  .heading {
    height: 40vh;
    width: 100vw;

    .brand-logo {
      height: 20vh;
      align-items: center;
    }

    .GoBiz-logo {
      border-radius: 50%;
      width: 100px;
    }
  }

  .body {
    height: 50vh;

    .phone-number-input {
      height: 15vh;
      width: 100vw;

      .prompt {
        height: 5vh;
        font-size: 22px;
        font-family: Sansita;
      }

      .phone-number-hints {
        margin: 5px 0px 5px 15px;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        .country-code {
          padding: 5px;
          font-family: Patrick Hand;
          font-size: 18px;
        }
      }

      .input-field {
        margin: 5px 15px 5px 5px;
        border: none;

        font-family: Patrick Hand;
        font-size: 18px;
      }

      .input-errors {
        margin: -20px 0px 0px 0px;
        height: 2vh;
        font-size: 12px;
        padding: 10px 40px 0px 40px;
      }
    }

    .buttons {
      height: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .reset-button {
        border-radius: 8px;
        border: 4px solid var(--accent-crayola-caribbean-green, #37d4ad);
        background: var(--accent-crayola-caribbean-green, #37d4ad);
        width: 201px;
        height: 52px;

        color: var(--dark-gray, #2a2f32);
        font-family: Sansita;
        font-size: 22px;
      }

      .reset-button:disabled {
        opacity: 0.3;
        color: white;
      }

      .back-button {
        border-radius: 8px;
        border: 4px solid var(--dark-gray, #395b88);
        background: var(--dark-gray, #395b88);

        color: var(--background-caribbean-green, #dafcf3);
        font-family: Sansita;
        font-size: 22px;
      }
    }
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
    .input-wrapper {
      background-color: white;
      width: 275px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 200px;
        letter-spacing: 0.4rem;
      }
    }
  }

  /* Laptop/Desktop Styles */
  @media (min-width: 767px) {
    .input-wrapper {
      background-color: white;
      width: 285px;
      border-radius: 5px;
      color: black;
      display: flex;

      .input-field {
        width: 250px;
        letter-spacing: 0.45rem;
      }
    }
  }
}
</style>
