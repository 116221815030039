<template>
  <div class="footer">
    <img src="../assets/images/trademark-logo.svg" />
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
